import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import WelcomePage from './pages/WelcomePage';
import Error from './pages/Error';
import Summary from './pages/Summary';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="summary" element={<Summary />} /> */}
        <Route exact path="/" element={<WelcomePage />} />
        <Route exact path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
