import React from 'react'
// import background from '../images/summary_background.svg'


const Error = () => {
    return (
        <div className='min-h-screen bg-[#F4F4F4]'>
            {/* <div className="bg-image h-screen" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}> */}
            <>
                <div class="flex h-[100%]">
                    <div class="m-auto">
                        <div className='flex flex-col items-center text-white'>
                            <span className='font-semibold text-6xl mb-4'>Not found</span>
                            <span className='text-2xl text-center'>The page you are searching for, cannot be found </span>
                        </div>
                    </div>
                </div>
            </>
            {/* </div> */}
        </div>
    )
}

export default Error